html,
body {
  margin: 0;
  padding: 0;
  font-family: 'Raleway', sans-serif;
  background: #01204C;
  height: 100%;
  overflow: hidden;
}

* {
  box-sizing: border-box;
}

body {
  display: flex;
  flex-direction: column;
}

.wrap {
  display: flex;
  width: 100vw;
  flex-grow: 1;
  position: relative;
}

.dropzone {
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.placeholder {
  width: 100%;
  max-width: 500px;
  border-radius: 0.5em;
  background: #EEE;
  padding: 2em;
  text-align: center;
}

.placeholder p {
  font-size: 1.2rem;
  color: #999;
}

.viewer {
  width: 100%;
  height: 100%;
  flex-grow: 1;
  flex-shrink: 1;
  position: absolute;
  top: 0;
  z-index: 0;
}

.axes {
  width: 100px;
  height: 100px;
  margin: 20px;
  padding: 0px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  z-index: 10;
  pointer-events: none;
}

/******************************************************************************
 * Validation report
 */

.report {
  padding: 2em;
  max-width: 860px;
}

.report h1 {
  margin-top: 0;
}

.report p,
.report ul {
  line-height: 1.5em;
}

.report-table {
  text-align: left;
  border-collapse: collapse;
  width: 100%;
}

.report-table thead tr {
  background: #404040;
  color: #FFF;
}

.report-table th,
.report-table td {
  padding: 0.5em 1em;
}

.report-table tr:nth-child(2n) {
  background: #F0F0F0;
}

/******************************************************************************
 * Loader
 */

.loader {
  border: 16px solid #f3f3f3;
  border-radius: 50%;
  border-top: 16px solid #0F94F3;
  width: 120px;
  height: 120px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  margin: auto;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  position: fixed;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }

  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/******************************************************************************
 * Validation toggle
 */

.report-toggle-wrap.hidden {
  display: none;
}

.report-toggle {
  cursor: pointer;
  display: flex;
  position: absolute;
  bottom: 0;
  left: 20px;
  height: 30px;
  box-shadow: 0px 0px 5px 0 rgba(0, 0, 0, 0.25);
  background: #FFF;
  box-sizing: border-box;

  color: #f0f0f0;
  background: #000;
  border-left: 6px solid #000;
}

.report-toggle.level-1 {
  color: #444;
  background: #ffeda0;
  border-left-color: #feb24c;
}

.report-toggle.level-0 {
  color: #444;
  background: #f4c2be;
  border-left-color: #b10026;
}

.report-toggle-text {
  line-height: 32px;
  padding: 0 0.5em;
  font-weight: 300;
  font-size: 0.8em;
}

.report-toggle-close {
  width: 30px;
  height: 30px;
  line-height: 30px;
  font-size: 1.5em;
  text-align: center;
}